<template>
  <div>
    <algolia-user-list
      :user-filter="'agents'"
      :route-func="hit => ({ path: `${link}/${hit.objectID}` })"
    >
      <template slot="nosearch" slot-scope="{ isEmpty }">
        <list v-show="isEmpty" :reference="reference" :excluded="['system']">
          <template slot-scope="props">
            <list-card
              :subtitle-grey="`${props.item.email}`"
              :title="(props.item.name || props.item.email || '').split('@')[0]"
              :label="getUserLabel(props.item)"
              :timestamp="props.item.dateCreated.toDate()"
              :route="{ path: `/admin/agents/${props.item._id}` }"
              :avatar="{ name: props.item.name, url: props.item.avatarUrl }"
            />
          </template>
        </list>
      </template>
    </algolia-user-list>
  </div>
</template>

<script>
import { collection, query, where } from "@firebase/firestore";
export default {
  name: "AdminAgents",
  components: {
    "algolia-user-list": () => import("@shared/user/_algoliaUserList.vue")
  },
  data() {
    return {
      reference: query(
        collection(this.$firestore, "users"),
        where("isAgent", "==", true)
      ),
      link: "/admin/agents",
      sorters: [
        {
          field: "isAdmin",
          label: "User Type"
        },
        {
          field: "name",
          label: "Name"
        },
        {
          field: "dateCreated",
          label: "Date Created"
        }
      ],
      activeSort: {
        field: "dateCreated",
        direction: "desc"
      }
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]();
    }
  },
  created() {
    if (!this.isAdmin) {
      return this.$router.push({ path: "/admin/dashboard" });
    }
  },
  methods: {
    getUserLabel(user) {
      if (user.isAdmin) return "admin";
      if (user.isAgent) return "agent";
      if (user.isReseller) return "reseller";
      if (user.isResellerUser) return "reseller-client";
      return "client";
    }
  }
};
</script>
